<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          ทำรายการถอนเงิน
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <b-row>
            <b-col cols="md-3 col-sm-6">
              <b-form-group>
                  <label class="control-label">Username</label>
                  <v-select v-model="selected_user" :options="paginated" :filterable="false" @search="onSearch" label="title" combobox-class="form-control" placeholder="เลือก Username">
                    <template #option="{ title, full_name }">
                      {{ title }} ({{ full_name }})
                    </template>
                  </v-select>
              </b-form-group>
              <b-form-group>
                  <label class="control-label">หมายเหตุ</label>
                <textarea type="text" v-model="withdraw.description" class="form-control" placeholder="ระบุหมายเหตุ"></textarea>
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-6">
              <b-form-group>
                  <label class="control-label">จำนวนเงิน</label>
                  <input type="text" v-model="withdraw.amount" class="form-control"  maxlength="20" placeholder="ข้อมูลจำนวนเงิน" style="text-align:right;">
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-6">
              <b-form-group>
                  <label class="control-label">บันทึกลง Transaction</label>
                  <b-form-select v-model="withdraw.transaction" :options="option_transactions" />
              </b-form-group>
            </b-col>
            <b-col cols="md-3 col-sm-6">
              <div class="jumbotron p-1">
                <p class="mb-0">เงื่อนไข/ข้อชี้แจ้ง "บันทึกลง Transaction"</p>
                <hr class="mt-1 mb-1">
                <ul class="list-unstyled mt-1">
                  <li>-&nbsp;&nbsp;เลือก "ใช่" ระบบจะบันทึกลงรายการถอนเงินและลดเครดิต MAIN WALLET ลูกค้า</li>
                  <li>-&nbsp;&nbsp;เลือก "ไม่" ระบบจะลดเครดิต MAIN WALLET ลูกค้าอย่างเดียวเท่านั้น</li>
                </ul>
              </div>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <button @click="submit_withdraw()" type="button" class="btn bg-gradient-success waves-effect waves-light text-white"><feather-icon icon="SaveIcon" class="font-medium-1 mr-1" />บันทึก</button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการถอนเงิน
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mx-auto mb-1">
            <b-col cols="12 col-md-9" class="pr-1 pl-1 mb-1">
              <div>
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ถอน (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ถอน (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-3">
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light mr-1"> ค้นหา </b-button>
              <b-button @click="this.filter=''" v-b-modal.modal-settings variant="btn-primary" class="btn bg-gradient-success waves-effect waves-light"> ตั้งค่าการถอน </b-button>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1">
              <b-row>
                <div class="btn-group btn-group-sm mx-auto" role="group">
                  <b-button variant="outline-secondary" class="btn" disabled>status: </b-button>
                  <b-button class="btn btn-warning" @click="sorted='status:0';fetch()">รอตรวจสอบ</b-button>
                  <b-button class="btn btn-success" @click="sorted='status:1';fetch()">ถอนออโต้</b-button>
                  <b-button class="btn btn-info" @click="sorted='status:3';fetch()">ถอนมือ</b-button>
                  <b-button class="btn btn-danger" @click="sorted='status:2';fetch()">ไม่อนุมัติ</b-button>
                  <b-button class="btn bg-gradient-primary" @click="sorted='';fetch()">ทั้งหมด</b-button>
                </div>
              </b-row>
            </b-col>
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(username)="data">
                  <span class="text-primary">{{ data.value }}</span>
                </template>
                <template #cell(bank)="data">
                  <div class="media-aside media-left align-self-start">
                    <img
                      class="media-object rounded"
                      :src="BASE_URL + 'assets/app-assets/images/bank/' + bank_admin[data.value] + '.png'"
                      :title="(typeof(bank_list[data.value]) != undefined ? bank_list[data.value] : '-')"
                      alt="Bank"
                      height="40"
                      width="40"
                    >
                    <div class="align-items-center ml-1">
                      <h5>{{data.item.bank_number}}</h5>
                      <span class="text-success text-nowrap">
                        {{data.item.full_name}}
                      </span>
                    </div>
                  </div>
                </template>
                <template #cell(full_name)="data">
                  <div class="text-left w-150">{{ data.item.username }}<br />({{ data.value }}) </div>
                </template>
                <template #cell(amount)="data">
                  <span class="text-success">{{ func.toMoney(data.value) }}</span>
                </template>
                <template #cell(status)="data">
                  <b-form-select v-if="['4'].includes(data.value)" @change="onChangeStatus($event, data.item.id)" v-model="data.value" v-bind="{'disabled': data.value != '0'}" :options="option_status" class="w-150" />
                  <b-form-select v-if="!['4'].includes(data.value)" @change="onChangeStatus($event, data.item.id)" v-model="data.value" v-bind="{'disabled': data.value != '0'}" :options="option_status.slice(0, 5)" class="w-150" />
                </template>
                <template #cell(created_at)="data">
                  <div v-for="value in data.value.split(' ')" :key="value" > {{ value }} </div>
                </template>
                <template #cell(max_value)="data">
                  <b-button v-b-toggle="'collapse-'+data.item.id" variant="btn-primary" class="btn withdraw_btn waves-effect waves-light" :class="{ 'bg-gradient-warning': data.item.status == 0, 'bg-gradient-success': data.item.status == 1 || data.item.status == 5, 'bg-gradient-danger': data.item.status == 2, 'bg-gradient-info': data.item.status == 3 }">
                    {{ getContext(data.item.max_value).slice(0, 20) }}
                  </b-button>
                  <b-collapse :id="'collapse-'+data.item.id" class="mt-2">
                    <div class="card card-body">
                      {{ getContext(data.item.max_value) }}
                    </div>
                  </b-collapse>
                </template>
                <template #cell(manage)="data">
                  <div class="btn-group">
                    <b-dropdown variant="link" toggle-class="bg-gradient-success waves-effect waves-light">
                      <template #button-content>
                        <span><feather-icon icon="EditIcon" class="font-medium-1 mr-1" /></span>จัดการ<span v-if="data.item.status == 0" class="badge badge-danger">1</span>
                      </template>
                      <b-dropdown-item :to="'/withdraw/withdraw_form_detail/' + data.item.id"><span><feather-icon icon="SearchIcon" class="text-info mr-1" /></span>ตรวจสอบ</b-dropdown-item>
                      <b-dropdown-item @click="modal_winlost" :data-username="data.item.username"><span><feather-icon icon="TrendingUpIcon" class="text-success mr-1" /></span>สรุปชนะแพ้</b-dropdown-item>
                      <b-dropdown-item @click="modal_viewlog" v-b-modal.modal-viewlog :data-username="data.item.username"><span><feather-icon icon="DollarSignIcon" class="text-warning mr-1" /></span>เครดิตลิมิต</b-dropdown-item>
                      <b-dropdown-item @click="modal_payment" v-b-modal.modal-payment :data-username="data.item.username"><span><feather-icon icon="InfoIcon" class="text-secondary mr-1" /></span>ธุรกรรมสมาชิก</b-dropdown-item>
                      <template v-if="data.item.process == false || data.item.status == '2'">
                        <b-dropdown-divider />
                        <b-dropdown-item :data-id="data.item.id"><span><feather-icon icon="RefreshCwIcon" class="text-danger mr-1" /></span>ถอนอีกครั้ง</b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-modal
      id="modal-payment"
      centered
      size="lg"
      title="ธุรกรรมสมาชิก"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-primary waves-effect waves-light"
    >
      <b-row class="mb-1">
        <b-col cols="12">
          <ul class="list-group"> 
            <li class="list-group-item ">
              <span class="font-weight-bold">USERNAME</span> : <span class="text-uppercase">{{ username }}</span>
              <button class="btn btn-white btn-sm float-right" @click="payment()">ค้นหา</button>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text" label="date_start_report2"> จากวันที่ </span>
              </b-input-group-prepend>
              <flat-pickr :config="{dateFormat:'m/d/Y'}" v-model="date_start_report2" id="date_start_report2" class="form-control text-center" />
              <b-input-group-prepend>
                <span class="input-group-text" label="date_end_report2"> ถึงวันที่ </span>
              </b-input-group-prepend>
              <flat-pickr :config="{dateFormat:'m/d/Y'}" v-model="date_end_report2" id="date_end_report2" class="form-control text-center" />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <b-table hover responsive :fields="fieldsPayment" :items="dataPayment" show-empty>
            <template #empty="">
              <div class="align-items-center text-center">ไม่พบข้อมูล</div>
            </template>
            <template #emptyfiltered="">
              <div class="align-items-center text-center">ไม่พบข้อมูล</div>
            </template>
            <template #cell(amount)="data">
              <span class="text-warning" v-if="data.item.detail.includes('by agent')">{{ data.value }}</span>
              <span class="text-danger" v-else-if="data.value.includes('-')">{{ data.value }}</span>
              <span class="text-success" v-else>{{ data.value }}</span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
    <!-- สิ้นสุดแถบ payment -->
    <b-modal
      id="modal-viewlog"
      centered
      size="lg"
      title="เครดิตลิมิต"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-primary waves-effect waves-light"
    >
      <b-row class="mb-1">
        <b-col cols="12">
          <ul class="list-group"> 
            <li class="list-group-item ">
              <span class="font-weight-bold">USERNAME</span> : <span class="text-uppercase">{{ username }}</span>
              <button class="btn btn-white btn-sm float-right" @click="viewlog()">ค้นหา</button>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text" label="date_start_report2"> จากวันที่ </span>
              </b-input-group-prepend>
              <flat-pickr :config="{dateFormat:'m/d/Y'}" v-model="date_start_report2" id="date_start_report2" class="form-control text-center" />
              <b-input-group-prepend>
                <span class="input-group-text" label="date_end_report2"> ถึงวันที่ </span>
              </b-input-group-prepend>
              <flat-pickr :config="{dateFormat:'m/d/Y'}" v-model="date_end_report2" id="date_end_report2" class="form-control text-center" />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <b-table hover responsive :fields="fieldsViewlog" :items="dataViewlog" show-empty>
            <template #empty="">
              <div class="align-items-center text-center">ไม่พบข้อมูล</div>
            </template>
            <template #emptyfiltered="">
              <div class="align-items-center text-center">ไม่พบข้อมูล</div>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(date)="data">
              <h6 class="mb-0">{{ data.value }}</h6>
              <span class="text-secondary">{{ data.item.time }}</span>
            </template>
            <template #cell(username)="data">
              <h6 class="mb-0">{{ data.value }}</h6>
              <span class="text-secondary">{{ data.item.username_type }}</span>
            </template>
            <template #cell(before)="data">
              <span class="text-secondary">{{ data.value }}</span>
            </template>
            <template #cell(amount)="data">
              <span class="text-danger" v-if="data.item.deposit == 0">{{ func.toMoney(data.value) }}</span>
              <span class="text-success" v-else>{{ func.toMoney(data.value) }}</span>
            </template>
            <template #cell(admin_username)="data">
              <h6 class="mb-0">{{ data.value }}</h6>
              <span class="text-secondary">{{ data.item.admin_type }}</span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
    <!-- สิ้นสุดแถบ viewlog -->
    <b-modal
      id="modal-settings"
      centered
      size="sm"
      title="ตั้งค่าการถอน"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-success waves-effect waves-light">
      <b-row class="mb-1">
        <b-col cols="12">
          <ul class="list-group"> 
            <li class="list-group-item ">
              <span class="font-weight-bold">ตรวจสอบยอด</span> : <span class="text-uppercase">10:00 PM</span>
              <button class="btn btn-white btn-sm float-right" @click="viewlog()">เปิด/ปิด</button>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-modal>
    <!-- สิ้นสุดแถบ withdraw settings -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
  BCollapse, VBToggle, BModal, VBModal, BAlert, BFormCheckbox, BSidebar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable, BCollapse,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton, BModal, BAlert, BFormCheckbox, BSidebar,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      date_start_report2: '',
      date_end_report2: '',
      username: '',
      limit: 20,
      search: '',
      selected_user: { title: 'เลือก Username', id: '' },
      option_users: [],
      option_transactions: [
        { text: 'ใช่', value: 'Y' },
        { text: 'ไม่', value: 'N' },
      ],
      selected_status: { text: 'รอตรวจสอบ', value: '0' },
      option_status: [
        { text: 'รอตรวจสอบ', value: '0' },
        { text: 'สำเร็จ (ถอนออโต้)', value: '1' },
        { text: 'สำเร็จ (ถอนมือ)', value: '3' },
        { text: 'สำเร็จ (ไม่ถอน)', value: '5' },
        { text: 'ไม่อนุมัติ', value: '2' },
        { text: 'ดำเนินการถอนออโต้', value: '4' },
      ],
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      sorted: '',
      filter: '',
      filterOn: [],
      fields: [
        { key: 'username', label: 'ยูสเซอร์', class: 'text-center text-nowrap' },
        { key: 'bank', label: 'ชื่อ - นามสกุล', class: 'text-left' },
        { key: 'created_at', label: 'เวลารายการ', class: 'text-center text-nowrap' },
        { key: 'amount', label: 'จำนวนเงิน', class: 'text-right text-nowrap' },
        { key: 'admin_username', label: 'ทำรายการโดย', class: 'text-right' },
        { key: 'status', label: 'สถานะ', class: 'text-center text-nowrap' },
        { key: 'max_value', label: 'รายละเอียด', class: 'text-center' },
        { key: 'manage', label: 'จัดการข้อมูล', class: 'text-center' },
      ],
      items: [],
      fieldsPayment: [
        { key: 'at', label: 'วันที่จ่าย', class: 'text-left text-nowrap' },
        { key: 'date', label: 'วันที่ทำรายการ', class: 'text-left text-nowrap' },
        { key: 'detail', label: 'รายละเอียด', class: 'text-left text-nowrap' },
        { key: 'amount', label: 'จำนวน', class: 'text-right text-nowrap' },
      ],
      dataPayment: [],
      fieldsViewlog: [
        { key: 'index', label: '#', class: 'text-center' },
        { key: 'date', label: 'วันเวลา', class: 'text-center' },
        { key: 'username', label: 'รหัสผู้ใช้', class: 'text-center' },
        { key: 'before', label: 'จากค่า', class: 'text-center' },
        { key: 'after', label: 'เป็นค่า', class: 'text-center' },
        { key: 'amount', label: 'จำนวน', class: 'text-right text-nowrap' },
        { key: 'admin_username', label: 'แก้ไขโดย', class: 'text-center text-nowrap' },
      ],
      dataViewlog: [],
      t_fetch: 0,
      withdraw: {
        account_id: '',
        amount: '',
        transaction: 'Y',
      },
      bank_admin: {'01': '1', '02': '2', '03': '3', '04': '5', '05': '6', '06': '4', '07': '7', '08': '9', '09': 'baac'},
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  computed: {
    filtered() {
      return this.option_users.filter((u) => 
        u.title.toLowerCase().includes(this.search.toLowerCase()) ||
        u.full_name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
  },
  mounted() {
    if (this.$route.query.ref) {
      this.filter = 'ref:' + this.$route.query.ref;
    }
    this.totalRows = this.items.length
    this.fetch()
    this.fetch_username()
    this.t_fetch = setInterval(() => {
      if (this.currentPage != 1) return;
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    onSearch(query) {
      this.search = query
    },
    getContext(data) {
      let text = (data == '') ? 'รออนุมัติยอด' : (data.includes('{') && typeof JSON.parse(data) == 'object') ? JSON.parse(data)['message:'] != null ? JSON.parse(data)['message:'] : JSON.parse(data).message : data
      return text
    },
    onFiltered(filteredItems) {
      if (this.filter != '' && this.filter.startsWith('status:')) {
        this.filter = ''
      }
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        willOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `withdraw/withdraw_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.sorted != '' ? this.sorted : this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
    fetch_username() {
      this.$http.get('credit/username').then(res => { res.data.forEach(data=> this.option_users.push({title: data.account_agent_username !== '' ? data.account_agent_username : data.full_name , id: data.id, full_name: data.full_name })) })
    },
    onChangeStatus(value, id) {
      let type = ``;
      switch (value) {
        case '0':
          type = 'ยืนยันการทำรายการ รอตรวจสอบ';
          break;
        case '1':
          type = 'ยืนยันการทำรายการ สำเร็จ (ถอนออโต้)';
          break;
        case '2':
          type = 'ยืนยันการทำรายการ ไม่อนุมัติ';
          break;
        case '3':
          type = 'ยืนยันการทำรายการ สำเร็จ (ถอนมือ)';
          break;
        case '5':
          type = 'ยืนยันการทำรายการ สำเร็จ (ไม่ถอน)';
          break;
        default:
      }
      this.$swal({
        title: 'แจ้งเตือน',
        html: type,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
		    cancelButtonColor: 'red',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mr-1',
        },
        buttonsStyling: false,
		    reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.loading()
          this.$http.post('withdraw/withdraw_status/' + id, { status: value }).then(res => {
            this.$swal.close()
            if (res.data.result) {
              this.fetch()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ทำรายการสำเร็จ',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: res.data.message,
                },
              })
            } else {
              if(res.data.error && res.data.message != null) {
                this.warn(res.data.message)
              } else {
                this.warn('ทำรายการไม่สำเร็จ')
              }
              this.fetch()
            }
          }).catch((error) => {
            this.$swal.close()
            this.warn('ทำรายการไม่สำเร็จ error:'+error)
          })
        } else {
          if (value > 0) {}
        }
      })
    },
    submit_withdraw() {
      let { id, title } = this.selected_user
      this.withdraw.account_id = id
      if (this.withdraw.account_id.toString().trim().length == "") {
        return this.warn('กรุณาเลือก Username');
      }
      if (this.withdraw.amount.toString().trim().length == 0) {
        return this.warn('กรุณาระบุจำนวนเงิน');
      }
      if (this.withdraw.amount == 0) {
        return this.warn('จำนวนเงินต้องมากกว่า 0');
      }
      this.loading()
      this.$http.post('withdraw/withdraw_credit', this.withdraw).then(res => {
        this.$swal.close()
        if (res.data.result) {
          this.fetch()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'ทำรายการสำเร็จ',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'ถอนเงิน ยูสเซอร์ : '+title+' จำนวน => '+this.func.toMoney(Number(this.withdraw.amount))+' บาท',
            },
          })
          this.withdraw.amount = '';
        } else {
          this.warn(res.data.message)
        }
      }).catch((error) => {
        this.$swal.close()
        this.warn('ทำรายการไม่สำเร็จ error:'+error)
      })
    },
    modal_payment(e) {
      if (!e.target.dataset) return;
      this.username = e.target.dataset.username;
      let date = new Date().toISOString().split('T')[0].split('-');
      this.date_start_report2 = `${date[1]}/${date[2]}/${date[0]}`;
      this.date_end_report2 = `${date[1]}/${date[2]}/${date[0]}`;
      this.payment();
    },
    payment() {
      let url = `withdraw/payment`;
      let data = { username: this.username, from: this.date_start_report2, to: this.date_end_report2}
      this.loading()
      this.$http.post(url, data).then(res => {
        this.$swal.close()
        if (res.data != null && res.data.result != null)
          this.dataPayment = res.data.result
      })
    },
    modal_viewlog(e) {
      if (!e.target.dataset) return;
      this.username = e.target.dataset.username;
      let date = new Date().toISOString().split('T')[0].split('-');
      this.date_start_report2 = `${date[1]}/${date[2]}/${date[0]}`;
      this.date_end_report2 = `${date[1]}/${date[2]}/${date[0]}`;
      this.viewlog();
    },
    viewlog() {
      let url = `credit/viewlog`;
      let data = { username: this.username, from: this.date_start_report2, to: this.date_end_report2}
      this.loading()
      this.$http.post(url, data).then(res => {
        this.$swal.close()
        if (res.data != null && res.data.result != null)
          this.dataViewlog = res.data.result
      })
    },
    modal_winlost(e) {
      if (!e.target.dataset) return;
      this.username = e.target.dataset.username;
      let date = new Date().toISOString().split('T')[0].split('-');
      this.date_start_report2 = `${date[1]}/${date[2]}/${date[0]}`;
      this.date_end_report2 = `${date[1]}/${date[2]}/${date[0]}`;
      this.winlost();
    },
    winlost() {
      let url = `credit/winlost`;
      let data = { username: this.username, from: this.date_start_report2, to: this.date_end_report2}
      this.loading()
      this.$http.post(url, data).then(res => {
        this.$swal.close()
        if (res.data != null && res.data.result != null) {
          this.$swal({
            title: 'รายงานบัญชีแพ้ชนะยูสเซอร์ ' + this.username,
            html: typeof res.data.result == 'object' ? `<h6>เทิร์น ${res.data.result.turn}</h6><span class="text-${res.data.result.winlost >= 0 ? 'success' : 'danger'}">ยอดรวม ${res.data.result.winlost}</span>` : JSON.stringify(res.data.result),
            icon: 'success',
            confirmButtonText: 'ตกลง',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style scoped>
.withdraw_btn {
  min-width: 120px;
  max-width: 120px;
}
.w-150 {
  width: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
